import React from "react"
import Layout from "../../components/layout/Layout"
import Markdown from "../../components/markdown/Markdown"
import Section from "../../components/section/Section"
import css from "./Campaign.module.css"

export default ({ pageContext: { url, node, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.campaign}
      slug={`${url}/${node.slug}`}
      title={node.nombre}
    >
      <Section className={css.header}>
        <div className={css.headerContainer}>
          <h1>{node.nombre}</h1>
          <Markdown className={css.body}>
            {node.presentacion.childMarkdownRemark.html}
          </Markdown>
          <img src={node.portada?.file.url} alt="img"></img>
        </div>
      </Section>
      <Section className={css.body}>
        <Markdown className={css.bodyContainer}>
          {node.cuerpo.childMarkdownRemark.html}
        </Markdown>
        {node.documentos && (
          <div className={css.docs}>
            <h2>Documentos</h2>
            <div className={css.docsGrid}>
              {node.documentos.map((document, i) => (
                <a
                  href={document.archivo.file.url}
                  target="_blank"
                  rel="noreferrer"
                  key={i}
                  className={css.doc}
                >
                  <span className="material-icons">subject</span>
                  <p>{document.nombre}</p>
                </a>
              ))}
            </div>
          </div>
        )}
      </Section>
      {/* <section className="articles-related">
        <div className="container">
          <h2>Últimas artículos relacionados</h2>
          <Grid>
            {data.articles.slice(0, 4).map((item, i) => (
              <Card url={`/${item.slug}`} key={i}>
                <img src={item.portada?.file.url} alt="img"></img>
                <h3>{item.titulo}</h3>
                <Date date={item.fecha} />
                <p>{item.cuerpo.childMarkdownRemark.excerpt}</p>
              </Card>
            ))}
          </Grid>
        </div>
      </section> */}
    </Layout>
  )
}
